import React from 'react';
import { useHistory } from "react-router-dom";
import * as FaIcons from "react-icons/fa";

import './ListTasker.scss';
import pinIcon from '../../assets/images/pin.png';

const ListTasker = () => {
    const history = useHistory();

    const goToLog = () => {
        history.push("/login");
    }

    return(
        <div className="listtasker-home" id="search">
            <div className="listtasker-description">
                <div className="listtasker-description__title">Destape de Cañerías y limpieza de filtros</div>
                <div className="listtasker-description__details">
                    <span><strong>Reviews: </strong>
                        <FaIcons.FaStar />
                        <FaIcons.FaStar />
                        <FaIcons.FaStar />
                        <FaIcons.FaStar />
                        <FaIcons.FaStarHalfAlt />
                    </span>
                    <span><strong>Categoría: </strong> Plomería</span>
                    <span><img src={pinIcon} alt="" /> La Paz</span>
                </div>
            </div>
            <div className="listtasker-callout__button">
                <button className="btn btn-started" onClick={goToLog}>Contratar</button>
            </div>

            <div className="listtasker-description">
                <div className="listtasker-description__title">Destape de Cañerías y limpieza de filtros</div>
                <div className="listtasker-description__details">
                    <span><strong>Reviews: </strong>
                        <FaIcons.FaStar />
                        <FaIcons.FaStar />
                        <FaIcons.FaStar />
                        <FaIcons.FaStar />
                        <FaIcons.FaStarHalfAlt />
                    </span>
                    <span><strong>Categoría: </strong> Plomería</span>
                    <span><img src={pinIcon} alt="" /> La Paz</span>
                </div>
            </div>
            <div className="listtasker-callout__button">
                <button className="btn btn-started" onClick={goToLog}>Contratar</button>
            </div>

            <div className="listtasker-description">
                <div className="listtasker-description__title">Destape de Cañerías y limpieza de filtros</div>
                <div className="listtasker-description__details">
                    <span><strong>Reviews: </strong>
                        <FaIcons.FaStar />
                        <FaIcons.FaStar />
                        <FaIcons.FaStar />
                        <FaIcons.FaStar />
                        <FaIcons.FaStarHalfAlt />
                    </span>
                    <span><strong>Categoría: </strong> Plomería</span>
                    <span><img src={pinIcon} alt="" /> La Paz</span>
                </div>
            </div>
            <div className="listtasker-callout__button">
                <button className="btn btn-started" onClick={goToLog}>Contratar</button>
            </div>
        </div>
    )
}

export default ListTasker;