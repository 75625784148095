export default function validate(values) {
    let errors = {};
    if (!values.identificacion) {
        errors.identificacion = 'Nro Identificación es requerido';
    } else if (!/^([0-9])*$/.test(values.identificacion)) {
        errors.identificacion = 'Nro Identificación es inválido';
    }
    if (!values.email) {
        errors.email = 'Email es requerido';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Email es inválido';
    }
    if (!values.password) {
        errors.password = 'Password es requerido';
    } else if (values.password.length < 6) {
        errors.password = 'Password debe tener 6 o más caracteres';
    }
    if (!values.confirmed) {
        errors.confirmed = 'Debes confirmar tu password';
    } else if (values.confirmed !== values.password) {
        errors.confirmed = 'El password no se puede confirmar';
    }
    if (!values.fullname) {
        errors.fullname = 'Nombre es requerido';
    } else if (!/[A-Za-z]+\s+[A-Za-z]+$/.test(values.fullname)) {
        errors.fullname = 'Nombre es inválido';
    }
    if (!values.cellphone) {
        errors.cellphone = 'Teléfono es requerido';
    } else if (!/^([0-9])*$/.test(values.cellphone)) {
        errors.cellphone = 'Teléfono es inválido';
    }
    if (!values.address) {
        errors.address = 'Dirección es requerida';
    } else if (!/^[A-Za-z]+\s[0-9]+\s/.test(values.address)) {
        errors.address = 'Dirección es inválida';
    }
    return errors;
};