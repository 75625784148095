import React from 'react';
import RegisterServices from '../RegisterServices/RegisterServices';
import NavBar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer';
import './Dashboard.scss';

const Dashboard = () => {

    return(
        <React.Fragment>
            <NavBar />
            <div className="main-container">
                <RegisterServices />
            </div>
            <Footer />
        </React.Fragment>

    )
}
export default Dashboard;