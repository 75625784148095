import React from 'react';
import Item from '../Item/Item';

import './Feature.scss';

import registerIcon from '../../assets/images/employee.png';
import publishIcon from '../../assets/images/promotion.png';
import searchIcon from '../../assets/images/job-search.png';
import scheduleIcon from '../../assets/images/calendar.png';
import recomIcon from '../../assets/images/social-media.png';
import supportIcon from '../../assets/images/customer-support.png';

const Feature = () => {
    return(
        <div className="feature--content" id="feature">
            <div className="feature--div-title">Características Principales</div>
            <div className="feature--div-subtitle">Contacta ahora mismo a nuestros expertos para hacer de tu emprendimiento una realidad.</div>
            <Item title="Registrar" icon={registerIcon} description="Regístrate en Trabbi y empieza a buscar el servicio o profesional experto en el área que deseas." />
            <Item title="Publicar" icon={publishIcon} description="Publica el trabajo o servicio necesitas y encuéntralo en Trabbi" />
            <Item title="Buscar" icon={searchIcon} description="Selecciona el tipo de servicio que estás buscando y complementa el cuestionario básico de requerimientos" />
            <Item title="Agendar" icon={scheduleIcon} description="Selecciona al experto con la mejor oferta y establece la fecha deseada para el inicio del servicio." />
            <Item title="Recomendar" icon={recomIcon} description="Nuestros expertos fueron cuidadosamente seleccionados mediante tests de confianza para brindarte un servicio de alta calidad y confiabilidad." />
            <Item title="Soporte 24/7" icon={supportIcon} description="No te preocupes por nada, cualquier incidencia será atendida de forma inmediata." />
        </div>
    )
}

export default Feature;