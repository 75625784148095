import React from 'react';
import './Footer.scss';

import whatsappIcon from '../../assets/images/whatsapp.png';
import mailIcon from '../../assets/images/mail.png';
import pinIcon from '../../assets/images/pin.png';

const Footer = () => {
    return (
        <div className="footer">
            <div className="container-fluid">
                <div className="row">
                    {/*<div className="footer--contact-link">
                        <span>
                            <img className="footer--contact-icon" src={whatsappIcon} alt="whatsapp trabbi" /> 
                            Escribenos | +00 100 000 00 00
                        </span>
                        <span>
                            <img className="footer--contact-icon" src={mailIcon} alt="mail trabbi" /> 
                            Contáctanos | contacto@trabbi.com
                        </span>
                        <span>
                            <img className="footer--contact-icon" src={pinIcon} alt="pin trabbi" /> 
                            Ubicación | LATAM
                        </span>
                    </div> */}
                    <p className="footer--copyright-p">© Todos los derechos reservados. Trabbi</p>
                </div>
            </div>
        </div>
    );
}

export default Footer;