import React from 'react';
import './Category.scss';

import categoryIcon from '../../assets/icons/015-paint roller.svg';

const Category = () => {
    return(
        <div className="category--content" id="feature">
            <div className="category--div-title">Top de Categorías</div>
            <div className="category--card-container">
                <div className="category--card-icon">
                    <img src={categoryIcon} alt="Icon" />
                </div>
                <div className="category--card-text">
                    <p>Plomería</p>
                </div>
            </div>
            <div className="category--card-container">
                <div className="category--card-icon">
                    <img src={categoryIcon} alt="Icon" />
                </div>
                <div className="category--card-text">
                    <p>Carpintería</p>
                </div>
            </div>
            <div className="category--card-container">
                <div className="category--card-icon">
                    <img src={categoryIcon} alt="Icon" />
                </div>
                <div className="category--card-text">
                    <p>Enfermería</p>
                </div>
            </div>
            <div className="category--card-container">
                <div className="category--card-icon">
                    <img src={categoryIcon} alt="Icon" />
                </div>
                <div className="category--card-text">
                    <p>Limpieza</p>
                </div>
            </div>
            <div className="category--card-container">
                <div className="category--card-icon">
                    <img src={categoryIcon} alt="Icon" />
                </div>
                <div className="category--card-text">
                    <p>Plomería</p>
                </div>
            </div>
            <div className="category--card-container">
                <div className="category--card-icon">
                    <img src={categoryIcon} alt="Icon" />
                </div>
                <div className="category--card-text">
                    <p>Carpintería</p>
                </div>
            </div>
            <div className="category--card-container">
                <div className="category--card-icon">
                    <img src={categoryIcon} alt="Icon" />
                </div>
                <div className="category--card-text">
                    <p>Enfermería</p>
                </div>
            </div>
            <div className="category--card-container">
                <div className="category--card-icon">
                    <img src={categoryIcon} alt="Icon" />
                </div>
                <div className="category--card-text">
                    <p>Limpieza</p>
                </div>
            </div>
        </div>
    )
}
export default Category;