import React from 'react';
import './Landing.scss';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Banner from '../../components/Banner/Banner';
import Feature from '../../components/Feature/Feature';
import About from '../../components/About/About';
import ContactForm from '../../components/ContactForm/ContactForm';

const Landing = () => {
    return (
        <React.Fragment>
           <Header />
           <Banner />
           <Feature />
           <About />
           <ContactForm />
           <Footer />
        </React.Fragment>
    )
}

export default Landing;