import React from 'react';
import './SearchResult.scss';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ListTasker from '../../components/ListTasker/ListTasker';

const SearchResult = () => {
    return (
        <React.Fragment>
           <Header />
           <ListTasker />
           <Footer />
        </React.Fragment>
    )
}

export default SearchResult;