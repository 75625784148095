import React from 'react';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Category from '../../components/Category/Category';
import Search from '../../components/Search/Search';
import ListTasker from '../../components/ListTasker/ListTasker';

const Home = () => {
    return (
        <React.Fragment>
           <Header />
           <Search />
           <Category />
           <ListTasker />
           <Footer />
        </React.Fragment>
    )
}

export default Home;