import React from 'react';
import { Link } from 'react-router-dom';
import * as FaIcons from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './RegisterServices.scss';

const RegisterServices = () => {
    const onSubmit = (e) => {
        e.preventDefault();
        toast.success("Datos guardados exitosamente!!! 👍");
    }

    return (
        <React.Fragment>
        <div className="content-body">
            <div className="container-fluid">
                <div className="d-flex align-items-center mb-4">
                    <h4 className="fs-20 font-w600 mb-0 me-auto">Nuevo Servicio</h4>
                    <div>
                        <Link to="#" className="btn btn-secondary btn-sm me-3"> <FaIcons.FaEnvelope /></Link>
                        <Link to="#" className="btn btn-secondary btn-sm me-3"> <FaIcons.FaPhoneAlt /></Link>
                        <Link to="#" className="btn btn-primary btn-sm me-3"> <FaIcons.FaInfo /></Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xl-6 col-md-6 mb-4">
                                        <label className="form-label font-w600">Categoría<span className="text-danger scale5 ms-2">*</span></label>
                                        <select className="form-select form-control solid">
                                            <option>Elegir...</option>
                                            <option>Plomería</option>
                                            <option>Carpintería</option>
                                            <option>Enfermería</option>
                                        </select>
                                    </div>
                                    <div className="col-xl-6 col-md-6 mb-4">
                                        <label className="form-label font-w600">Titulo<span className="text-danger scale5 ms-2">*</span></label>
                                        <input type="text" className="form-control solid" placeholder="Titulo" aria-label="title" />
                                    </div>
                                    <div className="col-xl-4 col-md-4 mb-4">
                                        <label className="form-label font-w600">Periodo<span className="text-danger scale5 ms-2">*</span></label>
                                        <select className="form-select form-control solid">
                                            <option>Elegir...</option>
                                            <option value="hourly">Hora</option>
                                            <option value="daily">Dia</option>
                                            <option value="weekly">Semana</option>
                                        </select>
                                    </div>
                                    <div className="col-xl-4 col-md-4 mb-4">
                                        <label className="form-label font-w600">Precio Base<span className="text-danger scale5 ms-2">*</span></label>
                                        <input type="text" className="form-control solid" placeholder="$" aria-label="base_price" />
                                    </div>
                                    <div className="col-xl-4 col-md-4 mb-4">
                                        <label className="form-label font-w600">Oferta<span className="text-danger scale5 ms-2">*</span></label>
                                        <input type="text" className="form-control solid" placeholder="$" aria-label="offer" />
                                    </div>
                                    <div className="col-xl-12 mb-4">
                                        <label className="form-label font-w600">Descripción:<span className="text-danger scale5 ms-2">*</span></label>
                                        <textarea className="form-control solid" aria-label="description"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-end">
                                <div>
                                    <Link to="#" className="btn btn-primary me-3" onSubmit={onSubmit}>Guardar</Link>
                                    <Link to="#" className="btn btn-secondary">Cancelar</Link>
                                    <ToastContainer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

export default RegisterServices;
