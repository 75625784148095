import React from 'react';

import './About.scss';
import aboutImg from '../../assets/images/bg_about_us.png';

const About = () => {
    return (
        <div className="about--content" id="about">
            <div className="about--content-title">
                Acerca de Trabbi
            </div>
            <div className="about--content-div">
                <img src={aboutImg} alt="about Trabbi team" />
                <div className="about--content-description">
                    <p><strong>Trabbi</strong> se caracteriza por ser la plataforma líder en proveer servicios de calidad, confiabilidad y seguridad a precios razonables para el mercado latinoamericano.</p>
                    <p>Te acerca a miles de profesionales independientes y empresas que te están esperando ser contactadas para trabajar contigo en lo que tu necesites.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default About;