import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Profile.scss';

import imgMain from '../../assets/images/main_img.svg';
import imgPerfil from '../../assets/images/usuario-de-perfil.png';
import imgMotivacion from '../../assets/images/motivacion.jpg';
import imgPerseverancia from '../../assets/images/perseverancia.jpg';
import imgPeople from '../../assets/images/Yellow_People_28.jpg';


const Profile = () => {
    const onSubmit = (e) => {
        e.preventDefault();
        toast.success("Datos guardados exitosamente!!! 👍");
    }

    return (
        <React.Fragment>
        <div className="content-body">
            <div className="container-fluid">
                <div className="detail-profile">
                    <div className="user">
                        <img src={imgPerfil} alt="" />
                    </div>
                    
                    <div className="datos">
                        <span> Nombre: Rodrigo Andrés Gutierrez Mendoza </span>
                        <span> País: Chile </span>
                        <span> Ciudad : Chillán </span>
                        <span> Correo: RodrigoG@gmail.com</span>
                        <span> Profesión: Electricista </span>
                    </div>
                    
                    <div className="analytics">
                        <div className="container">
                            <span> Trabajos Realizados </span>
                            <span> 24 </span>
                        </div>
                        <div className="container internal">
                            <span> Valoraciones  </span>
                            <span> 87%  </span>
                            <span> muestra apreciación </span>
                        </div>
                        <div className="container recommen">
                            <span> Recomendaciones </span>
                            <span> 28 </span>
                            <span> personas </span>
                        </div>
                    </div>
                    
                </div>
                <div className="fotos">
                    <div className="imagen">
                        <span> Trabajo1</span>
                        <img src={imgMain} alt="" />
                    </div>
                
                    <div className="imagen">
                        <span> Trabajo2</span>
                        <img src={imgMain} alt="" />
                    </div>
                
                    <div className="imagen">
                        <span> Trabajo3</span>
                        <img src={imgMain} alt="" />
                    </div>
                </div>
                
                <div className="Habilidades">
                    <div className="titulo">
                        <span>INSIGNIAS</span>
                    </div>
                    <div className="fortalezas">
                        <div className="fotaleza">
                            <span>Motivacion</span>
                            <img src={imgMotivacion} alt="" />
                            <span>Muestra interés y pasión en lo que hace.</span>
                        </div>
                        <div className="fotaleza">
                            <span>Perseverancia</span>
                            <img src={imgPerseverancia} alt="" />
                            <span>Su capacidad de intentarlo lo alienta a seguir adelante.</span>
                        </div>
                        <div className="fotaleza"> 
                            <span>Cooperación</span>
                            <img src={imgPeople} alt="" />
                            <span>Su habilidad no se limita a estar solo, comparte su conocimiento.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

export default Profile;