import React from 'react';
import { Link } from "react-router-dom";

import logoTrabbi from '../../assets/images/trabbi_logo.png';

import './NavBar.scss';

const NavBar = () => {
    return (
        <React.Fragment>
            <div className="navbar">
                <Link to="#" className="menu-bars">
                    <img src={logoTrabbi} alt="" />
                </Link>
                <ul className="nav navbar-nav pull-right">
                    <li><Link to="/services" className="item-menu">Nuevo Servicio</Link></li>
                    <li><Link to="/profile" className="item-menu">Mi Perfil</Link></li>
                    <li><Link to="/profile" className="item-menu">Mis Servicios</Link></li>
                    <li><Link to="/profile" className="item-menu">Suscripción</Link></li>
                    <li><Link to="/" className="item-menu logout">Salir </Link></li>
                </ul>
            </div>
        </React.Fragment>
    )
}

export default NavBar;