import { useState, useEffect } from 'react';
import axios from 'axios';
import useToken from './useToken';

export default function useFindUser() {
   const [user, setUser] = useState(null);
   const { token, setToken } = useToken(null);
   const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const findUser = () => {
            axios.get('https://us-central1-trabbi-spa.cloudfunctions.net/api/users', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(res => {
                setUser(res.data.userCredentials);
                setLoading(false);
            }). catch(err => {
                setLoading(false);
            });
        }
        findUser();
    }, []);
    return {
        user,
        setUser,
        isLoading
    }
}