import React from 'react';
import './Item.scss';

const Item = ({title, icon, description}) => {
    return (
        <div className="item--content">
            <div className="item--content-icon"><img src={icon} alt={title} /></div>
            <div className="item--content-div">
                <div className="item--content-title">{title}</div>
                <div className="item--content-description">{description}</div>
            </div>
        </div>
    )
}

export default Item;