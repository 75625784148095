import React from 'react';
import Profile from '../Profile/Profile';
import NavBar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer';

const Layout = () => {
    return(
        <React.Fragment>
            <NavBar />
            <div className="main-container">
                <Profile />
            </div>
            <Footer />
        </React.Fragment>
        
    )
}
export default Layout;