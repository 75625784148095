import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import './Header.scss';

import logoTrabbi from '../../assets/images/trabbi_logo.png';
import txtTrabbi from '../../assets/images/trabbi.png';

const Header = () => {
    const [scrolled, setScrolled] = useState(false);

    const handleScroll=() => {
        const offset = window.scrollY;
        if(offset > 200 ) {
            setScrolled(true);
        }
        else{
            setScrolled(false);
        }
    }
    useEffect(() => {
        window.addEventListener('scroll',handleScroll)
    })

    let x = ['header'];
    if(scrolled) {
        x.push('scrolled');
    }

    return (
        <header className={x.join(" ")} id="header">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-12 col-sm-12">
                        <div className="mainmenu">
                            <div className="navbar__brand">
                                <img className="navbar__brand-logo" src={logoTrabbi} alt="Logo" width="auto" height="64" />
                                <img src={txtTrabbi} alt="texto Trabbi" height="44px" />
                            </div>
                            <ul className="nav navbar-nav pull-right">
                                <li><Link to="/" className="link-menu">Home</Link></li>
                                <li><Link to="/landing" className="link-menu">Producto</Link></li>
                                <li><Link to="/landing" className="link-menu">Somos</Link></li>
                                <li><Link to="/register" className="link-menu register">Registro</Link></li>
                                <li><Link to="/login" className="link-menu login">Login </Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;