import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useForm from '../../hooks/useForm';
import validate from '../../utils/validateForm';

import './Register.scss';

import logoTrabbi from '../../assets/images/trabbi_logo.png';
import Footer from '../../components/Footer/Footer';

const Register = () => {
    const history = useHistory();

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(onRegister, validate);

    const [identification, setIdentification] = useState();
    const [fullname, setFullname] = useState();
    const [cellphone, setCellphone] = useState();
    const [address, setAddress] = useState();
    const [city, setCity] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmed, setConfirmed] = useState();
    const [loader, setLoader] = useState(false);

    const onRegister = e => {
        e.preventDefault();
        setLoader(true);
        fetch('https://us-central1-trabbi-spa.cloudfunctions.net/api/users/signup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                identification: values.identification,
                fullname: values.fullname,
                email: values.email,
                cellphone: values.cellphone,
                phone: values.cellphone,
                city: values.city,
                address: values.address,
                password: values.password,
                confirmPassword: values.confirmed
            })
        }).then(data => {
            data.json();
            toast.success("Datos guardados exitosamente!!! 👍");
            setLoader(false);
            history.push("/login")
        }).catch((error) => {
            toast.error(error.message);
            setLoader(false);
        });

        setIdentification("");
        setFullname("");
        setCellphone("");
        setCity("");
        setAddress("");
        setEmail("");
        setPassword("");
        setConfirmed("");
    }

    return (
        <React.Fragment>
            <section className="container-fluid h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-10 col-sm-12">
                        <div className="register-content">
                            <div className="auth-form">
                                <div className="text-center"><img src={logoTrabbi} alt="" /></div>
                                <h4 className="text-center mb-4">Registra tus datos</h4>
                                <form onSubmit={handleSubmit} noValidate>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <label className="mb-1"><strong>Nro Identficación</strong></label>
                                            <input type="text" name="identificacion" onChange={handleChange} className={`form-control ${errors.identificacion && 'is-danger'}`} placeholder="Nro Identificación" value={values.identificacion || ''} required />
                                            <p className="help is-danger">{errors.identificacion}</p>
                                        </div>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <label className="mb-1"><strong>Nombre Completo</strong></label>
                                            <input type="text" name="fullname" onChange={handleChange} className={`form-control ${errors.fullname && 'is-danger'}`} placeholder="Tu nombre" value={values.fullname || ''} required />
                                            <p className="help is-danger">{errors.fullname}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <label className="mb-1"><strong>Email</strong></label>
                                            <input type="email" name="email" onChange={handleChange} className={`form-control ${errors.email && 'is-danger'}`} placeholder="hello@example.com" value={values.email || ''} required />
                                            <p className="help is-danger">{errors.email}</p>
                                        </div>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <label className="mb-1"><strong>Celular</strong></label>
                                            <input type="text" name="cellphone" onChange={handleChange} className={`form-control ${errors.cellphone && 'is-danger'}`} placeholder="6912020" value={values.cellphone || ''} required />
                                            <p className="help is-danger">{errors.cellphone}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <label className="mb-1"><strong>Password</strong></label>
                                            <input type="password" name="password" onChange={handleChange} className={`form-control ${errors.password && 'is-danger'}`} placeholder="*******" required />
                                            <p className="help is-danger">{errors.password}</p>
                                        </div>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <label className="mb-1"><strong>Confirmar Password</strong></label>
                                            <input type="password" name="confirmed" onChange={handleChange} className={`form-control ${errors.confirmed && 'is-danger'}`} placeholder="*******" required />
                                            <p className="help is-danger">{errors.confirmed}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <label className="mb-1"><strong>Ciudad</strong></label>
                                            <select className="form-control" name="city" onChange={e => setCity(e.target.value)} required>
                                                <option value="Cochabamba">La Paz</option>
                                                <option value="La Paz">Cochabamba</option>
                                                <option value="Potosí">Potosí</option>
                                                <option value="Beni">Beni</option>
                                                <option value="Chuquisaca">Chuquisaca</option>
                                                <option value="Pando">Pando</option>
                                                <option value="Santa Cruz">Santa Cruz</option>
                                                <option value="Oruro">Oruro</option>
                                                <option value="Tarija">Tarija</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <label className="mb-1"><strong>Dirección</strong></label>
                                            <input type="text" name="address" onChange={handleChange} className={`form-control ${errors.address && 'is-danger'}`} placeholder="Calle 20" required />
                                            <p className="help is-danger">{errors.address}</p>
                                        </div>
                                    </div>
                                    <div className="row float-right button">
                                        <div className="text-center mr-3">
                                            <Link to="/" className="btn btn-secondary btn-block">Cancelar</Link>
                                        </div>
                                        <div className="text-center">
                                            <button 
                                                type="submit"
                                                className="btn btn-primary btn-block"
                                                style={{ backgroundColor: loader ? "#ccc" : " rgb(138, 213, 245)" }}>Registrar</button>
                                            <ToastContainer />
                                        </div>
                                    </div>
                                </form>
                                <div className="new-account mt-3">
                                    <p>¿Ya tienes cuenta? <Link className="text-primary" to="/login">Inicia sesión aquí</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </section>
            <Footer />
        </React.Fragment>
    )
}

export default Register;