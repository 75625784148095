import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';
import { UserContext } from '../hooks/UserContext';
import useFindUser from '../hooks/useFindUser';
import PrivateRoute from './PrivateRoute';

import Home from '../containers/Home/Home';
import Landing from '../containers/Landing/Landing';
import Register from '../containers/Register/Register';
import Login from '../containers/Login/Login';
import NotFound from '../containers/NotFound/NotFound';
import Layout from '../containers/Layout/Layout';
import Dashboard from '../containers/Dashboard/Dashboard';
import SearchResult from '../containers/SearchResult/SearchResult';
import DetailServices from '../containers/DetailServices/DetailServices';

const App = () => {
    const { user, setUser, isLoading } = useFindUser();

    return (
        <BrowserRouter forceRefresh={true}>
            <UserContext.Provider value={{ user, setUser, isLoading }}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/landing" component={Landing} />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/results" component={SearchResult} />
                    <PrivateRoute exact path="/services" component={Dashboard} />
                    <PrivateRoute exact path="/profile" component={Layout} />
                    <PrivateRoute exact path="/services/:id" component={DetailServices} />
                    <Route component={NotFound} />
                </Switch>
            </UserContext.Provider>
        </BrowserRouter>
    )
};

export default App;
