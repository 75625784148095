import React from 'react';
import { useHistory } from "react-router-dom";

import './Search.scss';

const Search = () => {
    /* const [taskers, setTaskers] = useState([]);
    const location = useLocation();
    const search = location.search.replace('?search=', '?q=');
    useEffect(() => {
        fetch('http://localhost:5000/api/items'+search)
            .then(response => response.json())
            .then(data => setTaskers(data.body));
    }, []); */

    const history = useHistory();
    const onSubmit = () => (history.push("/results"));

    return(
        <div className="search-home" id="search">
            <div className="search-home__title">Expertos al alcance de tu mano</div>
            <div className="search-home__subtitle">Encuentra al profesional, servicio o empresa que necesites.</div>
            <div className="search-container__form">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <select name="Ciudad" className="search-form__select">
                                <option value="Cochabamba">La Paz</option>
                                <option value="La Paz">Cochabamba</option>
                                <option value="Potosí">Potosí</option>
                                <option value="Beni">Beni</option>
                                <option value="Chuquisaca">Chuquisaca</option>
                                <option value="Pando">Pando</option>
                                <option value="Santa Cruz">Santa Cruz</option>
                                <option value="Oruro">Oruro</option>
                                <option value="Tarija">Tarija</option>
                            </select>
                        </div>
                        <div className="col-lg-5 col-sm-12">
                            <input type="text" placeholder="Buscar..." name="search" className="search-form__input" />
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <button className="btn btn-started">Buscar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Search;