import React from 'react';
import './DetailServices.scss';
import * as FaIcons from "react-icons/fa";
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import defaultImage from '../../assets/images/product/1.jpg'

const DetailServices = () => {
    return (
        <React.Fragment>
           <Header />
           <div className="content-body">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-3 col-lg-6 col-md-6 col-xxl-5 ">
                                    <div className="tab-content">
                                        <div role="tabpanel" className="tab-pane fade show active" id="first">
                                            <img className="img-fluid" src={defaultImage} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-9 col-lg-6 col-md-6 col-xxl-7 col-sm-12">
                                    <div className="product-detail-content">
                                        <div className="new-arrival-content pr">
                                            <h4>Solid Women's V-neck Dark T-Shirt</h4>
                                            <div className="comment-review star-rating">
                                                <ul>
                                                    <li><FaIcons.FaStar /></li>
                                                    <li><FaIcons.FaStar /></li>
                                                    <li><FaIcons.FaStar /></li>
                                                    <li><FaIcons.FaStar /></li>
                                                    <li><FaIcons.FaStarHalfAlt /></li>
                                                </ul>
                                                <span className="review-text">(34 reviews)</span>
                                            </div>
                                            <div className="d-table mb-2">
                                                <p className="price float-start d-block">$325.00</p>
                                            </div>
                                            <p>Disponibilidad: <span className="item"> Active</span>
                                            </p>
                                            <p>Product code: <span className="item">0405689</span> </p>
                                            <p>Brand: <span className="item">Lee</span></p>
                                            <p>Product tags:&nbsp;&nbsp;
                                                <span className="badge badge-success light">bags</span>
                                                <span className="badge badge-info light">clothes</span>
                                                <span className="badge badge-danger light">shoes</span>
                                                <span className="badge badge-warning light">dresses</span>
                                            </p>
                                            <p className="text-content">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing.</p>
                                            <div className="d-flex align-items-end flex-wrap mt-4">
                                                <button className="btn btn-started">Contratar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           <Footer />
        </React.Fragment>
    )
}

export default DetailServices;