import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';

import * as FaIcons from "react-icons/fa";
import useForm from '../../hooks/useForm';
import useToken from '../../hooks/useToken';
import validate from '../../utils/validateForm';

import logoTrabbi from '../../assets/images/trabbi_logo.png';
import Footer from '../../components/Footer/Footer';

import './Login.scss';

const Login = () => {
    const history = useHistory();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const { token, setToken } = useToken(null);

    const {
        handleSubmit,
        handleChange,
        values,
        errors
    } = useForm(onLogin, validate);

    function onLogin() {
        const postData = JSON.stringify({ email: values.email, password: values.password });
        axios.post('https://us-central1-trabbi-spa.cloudfunctions.net/api/users/login', postData, { headers: { 'Content-Type': 'application/json' }})
        .then(response => {
            setToken(response.data);
            history.push("/services")
        })
    }

    return (
        <React.Fragment>
            <section className="container-fluid h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-6 col-sm-12">
                        <div className="authincation-content">
                            <div className="auth-form">
                                <div className="text-center"><img src={logoTrabbi} alt="" /></div>
                                <h4 className="text-center mb-4">Ingresar a tu cuenta</h4>
                                <form onSubmit={handleSubmit} noValidate>
                                    <div className="mb-3">
                                        <label className="mb-1"><strong>Email</strong></label>
                                        <input type="email" name="email" onChange={handleChange}  className={`form-control ${errors.email && 'is-danger'}`} placeholder="hello@example.com"
                                        value={values.email || ''}
                                        required />
                                        <p className="help is-danger">{errors.email}</p>
                                    </div>
                                    <div className="mb-3">
                                        <label className="mb-1"><strong>Password</strong></label>
                                        <input type="password" name="password" onChange={handleChange} className={`form-control ${errors.password && 'is-danger'}`} placeholder="Password" required />
                                        <p className="help is-danger">{errors.password}</p>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-primary btn-block">Iniciar Sesión</button>
                                    </div>
                                </form>
                                <div className="new-account mt-3">
                                    <p>¿No tienes cuenta? <Link className="text-primary" to="/register">Registrate Aquí</Link></p>
                                </div>
                                <div className="new-account mt-3">
                                    <p> <Link className="text-secondary" to="/"><FaIcons.FaArrowAltCircleLeft /> Volver al Inicio</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    )
}

export default Login;