import React, {useState} from 'react';
import useForm from '../../hooks/useForm';
import validate from '../../utils/validateForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ContactForm.scss';

const ContactForm = () => {
    const [fullname, setFullname] = useState("");
    const [email, setEmail] = useState("");
    const [cellphone, setCellphone] = useState("");
    const [address, setAddress] = useState("");
    const [loader, setLoader] = useState(false);

    const {
        handleSubmit,
        handleChange,
        values,
        errors,
    } = useForm(onSubscribe, validate);

    function onSubscribe() {
        setLoader(true);

        fetch('https://us-central1-trabbi-spa.cloudfunctions.net/api/subscribers/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fullname: values.fullname,
                location: values.address,
                email: values.email,
                phone: values.phone
            })
        }, { mode: 'cors' }).then(() => {
            setLoader(false);
            toast.success("Gracias por suscribirte 👍");
        }).catch((error) => {
            toast.error(error.message);
            setLoader(false);
        });

        setFullname("");
        setEmail("");
        setCellphone("");
        setAddress("");
    };

    return(
        <div className="contact--form" id="contact">
            <div className="contact--form-title">¡Quieres conocer más!</div>
            <div className="contact--form-subtitle">Regístra tus datos aquí y empieza a formar parte de la red de expertos más grande de Latinoamérica</div>
            <div className="contact--form-form">
                <form onSubmit={handleSubmit} noValidate>
                    <div className="row">
                        <div className="col-sm-4">
                            <input type="text" placeholder="Nombre Completo" name="fullname" value={values.fullname || ''} 
                            onChange={handleChange} className={`form-control ${errors.fullname && 'is-danger'}`} required />
                            <p className="help is-danger">{errors.fullname}</p>
                        </div>
                        <div className="col-sm-4">
                            <input type="text" placeholder="Correo Electrónico" name="email" value={values.email || ''}
                            onChange={handleChange} className={`form-control ${errors.email && 'is-danger'}`} required />
                            <p className="help is-danger">{errors.email}</p>
                        </div>
                        <div className="col-sm-4">
                            <input type="text" placeholder="Celular" name="cellphone" value={values.cellphone || ''}
                            onChange={handleChange} className={`form-control ${errors.cellphone && 'is-danger'}`} required />
                            <p className="help is-danger">{errors.cellphone}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-8">
                            <input type="text" placeholder="¿Dónde estás ubicado?" name="address" value={values.address || ''}
                            onChange={handleChange} className={`form-control ${errors.address && 'is-danger'}`} required  />
                            <p className="help is-danger">{errors.address}</p>
                        </div>
                        <div className="col-sm-4">
                            <button id="btnSuscribe" 
                            type="submit"
                            style={{ backgroundColor: loader ? "#ccc" : " rgb(138, 213, 245)" }}
                            className="btn btn-send">Enviar</button>
                            <ToastContainer />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ContactForm;