import React from 'react';
import './Banner.scss';

const Banner = () => {
    return(
        <div className="banner-home" id="banner">
            <div className="banner-home__title">Expertos al alcance de tu mano</div>
            <div className="banner-home__subtitle">Trabajadores Independientes al alcance de tu mano. Encuentra al profesional, servicio o empresa que necesites y únete a miles de personas que estarán trabajando por tu proyecto.</div>
            <button className="btn btn-started">Comenzar</button>
        </div>
    )
}

export default Banner;